import React from 'react'
import { withTranslation } from 'react-i18next'

const NotFoundPage = () => (
	<div
		style={{
			margin: 50,
		}}
	>
		<h1>NOT FOUND</h1>
		<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
	</div>
)

export default withTranslation()(NotFoundPage)
